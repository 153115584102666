import React from "react";
import { Gallery } from "../components/gallery";
import { Layout } from "../layout";
import { BreadCrumb } from "../section/breadcrumb";
import WorkImg from "../assets/img/bg-work.jpeg";

const WorkDetailPage = ({ pageContext }) => {
  const { company, images } = pageContext.work;

  console.log("ima", images);

  return (
    <Layout>
      <section className="mb-6">
        <div
          style={{ backgroundImage: `url(${WorkImg})` }}
          className="relative flex items-center justify-center 2xl:-mt-2 w-screen h-40vh bg-cover bg-center overflow-hidden md:h-60vh"
        >
          <h1 className="flex flex-col items-center justify-center tracking-widest">
            <span className="text-white text-2xl md:text-4xl">WORK</span>
            <span className="mt-2 text-white text-xs md:text-base">
              実例紹介
            </span>
          </h1>
          <div>
            <svg
              className=" wave absolute bottom-0 inset-x-0 -mb-1 w-200vw text-white fill-current "
              viewBox="0 0 1440 64"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M360 19.483C240 -6.49433 120 -6.49433 0 19.483V63.5H1440V19.483C1320 45.4603 1200 45.4603 1080 19.483C960 -6.49433 840 -6.49433 720 19.483C600 45.4603 480 45.4603 360 19.483Z" />
            </svg>
          </div>
        </div>
      </section>
      <BreadCrumb title={`/ ${company}`} subTitle="実例紹介" to="/work" />
      <section className="mb-12 mx-auto px-6 md:mb-24 md:px-10 lg:mb-32 lg:max-w-5xl">
        <div className="flex flex-col items-center justify-center mb-12 lg:mb-20">
          <div className="mb-2 md:mb-4">
            <h2 className="text-primary-blue md:text-xl">{company}</h2>
          </div>
          <div className="w-8 h-0.5 bg-primary-blue md:w-12"></div>
        </div>
        <div className="w-full mx-auto">
          <Gallery images={images} />
        </div>
      </section>
    </Layout>
  );
};

export default WorkDetailPage;
