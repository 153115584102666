import React from "react";
import { Link } from "gatsby";
import loadable from "@loadable/component";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import lgZoom from "lightgallery/plugins/zoom";

const LightGallery = loadable(() => import("lightgallery/react"));

export const Gallery = ({ images }) => {
  return (
    <LightGallery plugins={[lgZoom]}>
      {images.map((image, index) => (
        <Link
          key={index}
          className="gallery-item cursor-pointer w-full h-32 md:h-64"
          data-src={image.url}
        >
          <img
            style={{ width: "100%", height: "100%" }}
            className="img-responsive object-cover rounded"
            src={image.url}
            alt=""
          />
        </Link>
      ))}
    </LightGallery>
  );
};
